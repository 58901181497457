import React from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { LeadStateProvider } from "../_lib/context";

import { LeadHeader } from "@leads/LeadHeader";
import { Grid } from "@leads/Grid";
import { TradeVehicles } from "@leads/cards/TradeVehicle";

const BasicView: LeadViewComponent = (props) => {
	return (
		<>
			<LeadHeader {...props} />
			<Grid>
				<TradeVehicles />
			</Grid>
		</>
	);
};

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});
